import { gql } from "@apollo/client";
import {
	FEATURE_FOR_COMPUTE_PRICE_FRAGMENT,
	FEATURE_FRAGMENT,
	PUBLIC_FEATURE_FRAGMENT,
} from "@graphQl/fragments/feature";
import { LEVEL_FRAGMENT, PUBLIC_SMALL_LEVEL_FRAGMENT } from "@graphQl/fragments/level";
import {
	LEVEL_DATA_FOR_SIMULATOR_FRAGMENT,
	LEVEL_DATA_FRAGMENT,
	PUBLIC_LEVEL_DATA_FRAGMENT,
} from "@graphQl/fragments/levelData";

export const PRICE_MODEL_FEATURE_KEY_FIELD_FRAGMENT = gql`
	fragment PriceModelFeatureKeyFieldFragment on PriceModelFeature {
		id
		uuid
	}
`;

export const PRICE_MODEL_FEATURE_FEATURE_ID_FRAGMENT = gql`
	${PRICE_MODEL_FEATURE_KEY_FIELD_FRAGMENT}
	fragment PriceModelFeatureFeatureIdFragment on PriceModelFeature {
		...PriceModelFeatureKeyFieldFragment
		featureId
	}
`;
export const PRICE_MODEL_FEATURE_CATEGORY_ID_FRAGMENT = gql`
	${PRICE_MODEL_FEATURE_KEY_FIELD_FRAGMENT}
	fragment PriceModelFeatureCategoryIdFragment on PriceModelFeature {
		...PriceModelFeatureKeyFieldFragment
		categoryId
	}
`;
export const PRICE_MODEL_FEATURE_PRICE_MODEL_ID_FRAGMENT = gql`
	${PRICE_MODEL_FEATURE_KEY_FIELD_FRAGMENT}
	fragment PriceModelFeaturePriceModelIdFragment on PriceModelFeature {
		...PriceModelFeatureKeyFieldFragment
		priceModelId
	}
`;
export const PRICE_MODEL_FEATURE_INDEX_FRAGMENT = gql`
	${PRICE_MODEL_FEATURE_KEY_FIELD_FRAGMENT}
	fragment PriceModelFeatureIndexFragment on PriceModelFeature {
		...PriceModelFeatureKeyFieldFragment
		index
	}
`;
export const PRICE_MODEL_FEATURE_SHOW_GIFT_FRAGMENT = gql`
	${PRICE_MODEL_FEATURE_KEY_FIELD_FRAGMENT}
	fragment PriceModelFeatureShowGiftFragment on PriceModelFeature {
		...PriceModelFeatureKeyFieldFragment
		allowBoosters
		feeBased
	}
`;
export const PRICE_MODEL_FEATURE_SIMPLIFY_VIEW_FRAGMENT = gql`
	${PRICE_MODEL_FEATURE_KEY_FIELD_FRAGMENT}
	fragment PriceModelFeatureSimplifyViewFragment on PriceModelFeature {
		...PriceModelFeatureKeyFieldFragment
		activateSimplifyView
	}
`;
export const PRICE_MODEL_FEATURE_WEIGHT_FRAGMENT = gql`
	${PRICE_MODEL_FEATURE_KEY_FIELD_FRAGMENT}
	fragment PriceModelFeatureWeightFragment on PriceModelFeature {
		...PriceModelFeatureKeyFieldFragment
		weight
	}
`;
export const PRICE_MODEL_FEATURE_EXTRA_FEE_NAME_FRAGMENT = gql`
	${PRICE_MODEL_FEATURE_KEY_FIELD_FRAGMENT}
	fragment PriceModelFeatureExtraFeeNameFragment on PriceModelFeature {
		...PriceModelFeatureKeyFieldFragment
		feature {
			id
			extraFeeName
		}
	}
`;
export const PRICE_MODEL_FEATURE_FEE_BASED_FRAGMENT = gql`
	${PRICE_MODEL_FEATURE_KEY_FIELD_FRAGMENT}
	fragment PriceModelFeatureFeeBasedFragment on PriceModelFeature {
		...PriceModelFeatureKeyFieldFragment
		feeBased
	}
`;
export const PRICE_MODEL_FEATURE_APPLY_DISCOUNTS_FRAGMENT = gql`
	${PRICE_MODEL_FEATURE_KEY_FIELD_FRAGMENT}
	fragment PriceModelFeatureApplyDiscountsFragment on PriceModelFeature {
		...PriceModelFeatureKeyFieldFragment
		applyDiscountOnRecurring
	}
`;
export const PRICE_MODEL_FEATURE_LEVEL_FRAGMENT = gql`
	${LEVEL_FRAGMENT}
	${PRICE_MODEL_FEATURE_KEY_FIELD_FRAGMENT}
	fragment PriceModelFeatureLevelFragment on PriceModelFeature {
		...PriceModelFeatureKeyFieldFragment
		level {
			...LevelFragment
		}
	}
`;
export const PUBLIC_PRICE_MODEL_FEATURE_LEVEL_FRAGMENT = gql`
	${PUBLIC_SMALL_LEVEL_FRAGMENT}
	${PRICE_MODEL_FEATURE_KEY_FIELD_FRAGMENT}
	fragment PublicPriceModelFeatureLevelFragment on PriceModelFeature {
		...PriceModelFeatureKeyFieldFragment
		level {
			...PublicSmallLevelFragment
		}
	}
`;
export const PRICE_MODEL_FEATURE_LEVEL_DATA_FRAGMENT = gql`
	${LEVEL_DATA_FRAGMENT}
	${PRICE_MODEL_FEATURE_KEY_FIELD_FRAGMENT}
	fragment PriceModelFeatureLevelDataFragment on PriceModelFeature {
		...PriceModelFeatureKeyFieldFragment
		levelData {
			...LevelDataFragment
		}
	}
`;

export const PRICE_MODEL_FEATURE_LEVEL_DATA_FOR_SIMULATOR_FRAGMENT = gql`
	${LEVEL_DATA_FOR_SIMULATOR_FRAGMENT}
	${PRICE_MODEL_FEATURE_KEY_FIELD_FRAGMENT}
	fragment PriceModelFeatureLevelDataForSimulatorFragment on PriceModelFeature {
		...PriceModelFeatureKeyFieldFragment
		levelData {
			...LevelDataForSimulatorFragment
		}
	}
`;
export const PUBLIC_PRICE_MODEL_FEATURE_LEVEL_DATA_FRAGMENT = gql`
	${PUBLIC_LEVEL_DATA_FRAGMENT}
	${PRICE_MODEL_FEATURE_KEY_FIELD_FRAGMENT}
	fragment PublicPriceModelFeatureLevelDataFragment on PriceModelFeature {
		...PriceModelFeatureKeyFieldFragment
		levelData {
			...PublicLevelDataFragment
		}
	}
`;
export const PRICE_MODEL_FEATURE_ALLOW_BOOSTERS_FRAGMENT = gql`
	${PRICE_MODEL_FEATURE_KEY_FIELD_FRAGMENT}
	fragment PriceModelFeatureAllowBoostersFragment on PriceModelFeature {
		...PriceModelFeatureKeyFieldFragment
		allowBoosters
	}
`;
export const PRICE_MODEL_FEATURE_ALLOW_COSTS_FRAGMENT = gql`
	${PRICE_MODEL_FEATURE_KEY_FIELD_FRAGMENT}
	fragment PriceModelFeatureAllowCostsFragment on PriceModelFeature {
		...PriceModelFeatureKeyFieldFragment
		allowCosts
	}
`;
export const PRICE_MODEL_FEATURE_PRICE_TYPE_FRAGMENT = gql`
	${PRICE_MODEL_FEATURE_KEY_FIELD_FRAGMENT}
	fragment PriceModelFeaturePriceTypeFragment on PriceModelFeature {
		...PriceModelFeatureKeyFieldFragment
		recurringPriceType
	}
`;
export const PRICE_MODEL_FEATURE_TIME_METRIC_FRAGMENT = gql`
	${PRICE_MODEL_FEATURE_KEY_FIELD_FRAGMENT}
	fragment PriceModelFeatureTimeMetricFragment on PriceModelFeature {
		...PriceModelFeatureKeyFieldFragment
		timeMetric
	}
`;
export const PRICE_MODEL_FEATURE_UNIT_METRIC_FRAGMENT = gql`
	${PRICE_MODEL_FEATURE_KEY_FIELD_FRAGMENT}
	fragment PriceModelFeatureUnitMetricFragment on PriceModelFeature {
		...PriceModelFeatureKeyFieldFragment
		recurringUnitMetric
	}
`;
export const PRICE_MODEL_FEATURE_ENABLE_EXTRA_FEES_FRAGMENT = gql`
	${PRICE_MODEL_FEATURE_KEY_FIELD_FRAGMENT}
	fragment PriceModelFeatureEnableExtraFeesFragment on PriceModelFeature {
		...PriceModelFeatureKeyFieldFragment
		enableOneShot
	}
`;
export const PRICE_MODEL_FEATURE_EXTRA_FEE_METRIC_FRAGMENT = gql`
	${PRICE_MODEL_FEATURE_KEY_FIELD_FRAGMENT}
	fragment PriceModelFeatureExtraFeeMetricFragment on PriceModelFeature {
		...PriceModelFeatureKeyFieldFragment
		oneShotUnitMetric
	}
`;

export const PUBLIC_PRICE_MODEL_FEATURE_FRAGMENT = gql`
	${PUBLIC_FEATURE_FRAGMENT}

	${PRICE_MODEL_FEATURE_CATEGORY_ID_FRAGMENT}
	${PUBLIC_PRICE_MODEL_FEATURE_LEVEL_FRAGMENT}
	${PUBLIC_PRICE_MODEL_FEATURE_LEVEL_DATA_FRAGMENT}
	${PRICE_MODEL_FEATURE_INDEX_FRAGMENT}
	${PRICE_MODEL_FEATURE_ALLOW_BOOSTERS_FRAGMENT}
	${PRICE_MODEL_FEATURE_ALLOW_COSTS_FRAGMENT}
	${PRICE_MODEL_FEATURE_TIME_METRIC_FRAGMENT}
	${PRICE_MODEL_FEATURE_UNIT_METRIC_FRAGMENT}
	${PRICE_MODEL_FEATURE_PRICE_TYPE_FRAGMENT}
	${PRICE_MODEL_FEATURE_EXTRA_FEE_METRIC_FRAGMENT}
	${PRICE_MODEL_FEATURE_ENABLE_EXTRA_FEES_FRAGMENT}
	${PRICE_MODEL_FEATURE_FEE_BASED_FRAGMENT}
	${PRICE_MODEL_FEATURE_EXTRA_FEE_NAME_FRAGMENT}
	${PRICE_MODEL_FEATURE_SIMPLIFY_VIEW_FRAGMENT}
	${PRICE_MODEL_FEATURE_FEATURE_ID_FRAGMENT}
	fragment PublicPriceModelFeatureFragment on PriceModelFeature {
		feature {
			...PublicFeatureFragment
		}
		...PriceModelFeatureCategoryIdFragment
		...PriceModelFeatureIndexFragment
		...PriceModelFeatureAllowBoostersFragment
		...PriceModelFeatureAllowCostsFragment
		...PriceModelFeaturePriceTypeFragment
		...PriceModelFeatureTimeMetricFragment
		...PriceModelFeatureUnitMetricFragment
		...PriceModelFeatureExtraFeeMetricFragment
		...PriceModelFeatureEnableExtraFeesFragment
		...PriceModelFeatureFeeBasedFragment
		...PriceModelFeatureExtraFeeNameFragment
		...PublicPriceModelFeatureLevelFragment
		...PublicPriceModelFeatureLevelDataFragment
		...PriceModelFeatureSimplifyViewFragment
		...PriceModelFeatureFeatureIdFragment
	}
`;

export const PRICE_MODEL_FEATURE_FRAGMENT = gql`
	${FEATURE_FRAGMENT}
	${PRICE_MODEL_FEATURE_CATEGORY_ID_FRAGMENT}
	${PRICE_MODEL_FEATURE_PRICE_MODEL_ID_FRAGMENT}
	${PRICE_MODEL_FEATURE_WEIGHT_FRAGMENT}
	${PRICE_MODEL_FEATURE_FEE_BASED_FRAGMENT}
	${PRICE_MODEL_FEATURE_LEVEL_FRAGMENT}
	${PRICE_MODEL_FEATURE_LEVEL_DATA_FRAGMENT}
	${PRICE_MODEL_FEATURE_INDEX_FRAGMENT}
	${PRICE_MODEL_FEATURE_ALLOW_BOOSTERS_FRAGMENT}
	${PRICE_MODEL_FEATURE_ALLOW_COSTS_FRAGMENT}
	${PRICE_MODEL_FEATURE_TIME_METRIC_FRAGMENT}
	${PRICE_MODEL_FEATURE_UNIT_METRIC_FRAGMENT}
	${PRICE_MODEL_FEATURE_PRICE_TYPE_FRAGMENT}
	${PRICE_MODEL_FEATURE_EXTRA_FEE_METRIC_FRAGMENT}
	${PRICE_MODEL_FEATURE_ENABLE_EXTRA_FEES_FRAGMENT}
	${PRICE_MODEL_FEATURE_FEATURE_ID_FRAGMENT}
	${PRICE_MODEL_FEATURE_EXTRA_FEE_NAME_FRAGMENT}
	${PRICE_MODEL_FEATURE_APPLY_DISCOUNTS_FRAGMENT}
	fragment PriceModelFeatureFragment on PriceModelFeature {
		id
		uuid
		feature {
			...FeatureFragment
		}
		...PriceModelFeatureCategoryIdFragment
		...PriceModelFeaturePriceModelIdFragment
		...PriceModelFeatureWeightFragment
		...PriceModelFeatureFeeBasedFragment
		...PriceModelFeatureLevelFragment
		...PriceModelFeatureLevelDataFragment
		...PriceModelFeatureIndexFragment
		...PriceModelFeatureAllowBoostersFragment
		...PriceModelFeatureAllowCostsFragment
		...PriceModelFeaturePriceTypeFragment
		...PriceModelFeatureTimeMetricFragment
		...PriceModelFeatureUnitMetricFragment
		...PriceModelFeatureExtraFeeMetricFragment
		...PriceModelFeatureEnableExtraFeesFragment
		...PriceModelFeatureFeatureIdFragment
		...PriceModelFeatureExtraFeeNameFragment
		...PriceModelFeatureApplyDiscountsFragment
	}
`;

export const PRICE_MODEL_FEATURE_FOR_COMPUTE_PRICE_FRAGMENT = gql`
	${FEATURE_FOR_COMPUTE_PRICE_FRAGMENT}

	${PUBLIC_PRICE_MODEL_FEATURE_LEVEL_DATA_FRAGMENT}
	${PRICE_MODEL_FEATURE_ALLOW_BOOSTERS_FRAGMENT}
	${PRICE_MODEL_FEATURE_ALLOW_COSTS_FRAGMENT}
	${PRICE_MODEL_FEATURE_TIME_METRIC_FRAGMENT}
	${PRICE_MODEL_FEATURE_UNIT_METRIC_FRAGMENT}
	${PRICE_MODEL_FEATURE_PRICE_TYPE_FRAGMENT}
	${PRICE_MODEL_FEATURE_EXTRA_FEE_METRIC_FRAGMENT}
	${PRICE_MODEL_FEATURE_ENABLE_EXTRA_FEES_FRAGMENT}
	${PRICE_MODEL_FEATURE_FEE_BASED_FRAGMENT}
	${PUBLIC_PRICE_MODEL_FEATURE_LEVEL_FRAGMENT}

	fragment PriceModelFeatureForComputePriceFragment on PriceModelFeature {
		feature {
			...FeatureForComputePriceFragment
		}
		...PublicPriceModelFeatureLevelFragment
		...PublicPriceModelFeatureLevelDataFragment
		...PriceModelFeatureAllowBoostersFragment
		...PriceModelFeatureAllowCostsFragment
		...PriceModelFeaturePriceTypeFragment
		...PriceModelFeatureTimeMetricFragment
		...PriceModelFeatureUnitMetricFragment
		...PriceModelFeatureExtraFeeMetricFragment
		...PriceModelFeatureEnableExtraFeesFragment
		...PriceModelFeatureFeeBasedFragment
	}
`;
